<template>
  <v-container fluid class="fill-height">
    <v-row>
      <v-col class="py-0 my-0" lg="6" md="6" sm="12">
        <v-form class="frmRegistro" ref="frmRegistro">
          <span class="centrado">
            <h1>Comienza ya con</h1>
          </span>
          <div style="text-align: center">
            <img
              src="../../../public/img/login/logo-aco.png"
              alt="logo"
              max-width="100%"
            />
          </div>
          <v-row>
            <v-col class="py-2 my-0" cols="12">
              <v-text-field
                name="name"
                label="Nombre Empresa"
                id="id"
                outlined
                persistent-placeholder
                :dense="!$vuetify.breakpoint.xlOnly"
                hide-details
                v-model="$store.state.enterprises.data.trade_name"
                autocomplete="off"
                :rules="[(v) => !!v || 'Datos Requeridos.']"
              ></v-text-field>
            </v-col>
            <v-col class="py-2 my-0" cols="12">
              <b>Persona de contacto (Administrador del sistema) </b>
            </v-col>
            <v-col class="py-2 my-0" cols="4">
              <v-text-field
                label="Nombre*"
                outlined
                persistent-placeholder
                :dense="!$vuetify.breakpoint.xlOnly"
                hide-details
                v-model="$store.state.enterprises.data.names"
                :rules="[(v) => !!v || 'Datos Requeridos.']"
              ></v-text-field>
            </v-col>

            <v-col class="py-2 my-0" cols="4">
              <v-text-field
                name="name"
                label="Apellido Paterno*"
                placeholder=""
                outlined
                persistent-placeholder
                :dense="!$vuetify.breakpoint.xlOnly"
                hide-details
                v-model="$store.state.enterprises.data.surname"
                :rules="[(v) => !!v || 'Datos Requeridos.']"
              ></v-text-field>
            </v-col>
            <v-col class="py-2 my-0" cols="4">
              <v-text-field
                name="name"
                label="Apellido Materno*"
                placeholder=""
                outlined
                persistent-placeholder
                :dense="!$vuetify.breakpoint.xlOnly"
                hide-details
                v-model="$store.state.enterprises.data.second_surname"
                :rules="[(v) => !!v || 'Datos Requeridos.']"
              ></v-text-field>
            </v-col>

            <v-col class="py-2 my-0" cols="12">
              <v-text-field
                label="Email*"
                outlined
                persistent-placeholder
                :dense="!$vuetify.breakpoint.xlOnly"
                :hide-details="!errorEmail"
                v-model="$store.state.enterprises.data.email"
                @change="validarCorreoExiste()"
                :error-messages="errorEmail"
                id="Email"
                placeholder="Ingresa tu email para recibir el código de acceso."
                :rules="[(v) => !!v || 'Datos Requeridos.']"
                :loading="validandoCorreo"
              ></v-text-field>
            </v-col>
            <v-col class="py-2 my-0" cols="12">
              <v-text-field
                label="Email Confirmación*"
                outlined
                persistent-placeholder
                :dense="!$vuetify.breakpoint.xlOnly"
                v-model="$store.state.enterprises.data.emailconfirmacion"
                :error-messages="errorEmail"
                id="EmailConfirmación"
                placeholder="Confirme su correo."
                :rules="[(v) => !!v || 'Datos Requeridos.']"
              ></v-text-field>
              <p
                v-if="$store.state.enterprises.data.email"
                class="my-0 py-0"
                style="background: yellow; padding: 0 5%"
              >
                **Deberás tener acceso al mismo**
              </p>
            </v-col>

            <v-col class="pb-0 pt-2 my-0" cols="12">
              <v-text-field
                outlined
                :dense="!$vuetify.breakpoint.xlOnly"
                v-model="$store.state.enterprises.data.clave"
                :type="typePassword ? 'password' : 'text'"
                label="Contraseña"
                :append-icon="typePassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="typePassword = !typePassword"
                :rules="passwordRules"
                autocomplete="new-password"
                class="my-0"
              ></v-text-field>
            </v-col>
            <v-col class="py-2 my-0" cols="12">
              <CboPais
                @paisSeleccionado="handlePaisSeleccionado"
                outlined
                persistent-placeholder="true"
                :dense="!$vuetify.breakpoint.xlOnly"
                :errorPais="errorPais"
              />
            </v-col>
            <v-col class="py-2 my-0" cols="12">
              <v-text-field
                name="name"
                label="Teléfono*"
                outlined
                persistent-placeholder
                :dense="!$vuetify.breakpoint.xlOnly"
                v-model="$store.state.enterprises.data.phone"
                :rules="[(v) => !!v || 'Datos Requeridos.']"
              ></v-text-field>
              <p
                v-if="$store.state.enterprises.data.phone"
                class="my-0 py-0"
                style="background: yellow; padding: 0 5%"
              >
                **Deberás tener acceso al mismo**
              </p>
            </v-col>
            <v-col class="py-2 my-0" cols="12">
              <v-btn color="info" v-if="enviarCodigo" @click="enviarCorreo()"
                >Enviar Código</v-btn
              >
              <p v-if="mostrarAdvertencia">
                Ingresa el código de 4 dígitos enviado a tu correo.

                <v-alert
                  outlined
                  icon="mdi-email-alert-outline"
                  dense
                  color="red"
                >
                  <p class="my-0 py-0 text--black">
                    Se le ha enviado un email, el mismo puede tardar
                    <span class="text--red">1 minúto</span> en llegar. <br />
                    <span class="text--red">
                      REVISAR TU BANDEJA DE SPAM / NO DESEADOS.
                    </span>
                  </p>
                </v-alert>
              </p>
              <v-snackbar
                v-model="snackbarEmail"
                :multi-line="multiLine"
                :color="colorSnakBar"
                :timeout="timeout"
              >
                <v-icon class="mx-1">mdi-{{ icon }}</v-icon>
                {{ text }}
              </v-snackbar>
            </v-col>
            <v-col class="py-2 my-0" cols="8">
              <v-text-field
                name="name"
                label="Código de acceso*"
                outlined
                persistent-placeholder
                :dense="!$vuetify.breakpoint.xlOnly"
                hide-details
                v-model="$store.state.enterprises.data.codigo"
              ></v-text-field>
            </v-col>
            <v-col class="py-2 my-0" cols="4" v-if="!reenviarCodigo">
              {{ minutes }}:{{ secondsFormatted }}
            </v-col>
            <v-col class="py-2 my-0" cols="12">
              <a @click="enviarCorreo()" v-if="!enviarCodigo && timeLeft <= 0"
                >Reenviar Código</a
              >
            </v-col>
            <v-col class="py-2 my-0 centrado" cols="12">
              <v-btn class="py-2" color="error" @click="crearNuevaEmpresa()"
                >Empezar prueba gratis de 30 días</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <!-- v-show="$vuetify.breakpoint.lg" -->
      <v-col class="py-2 my-0 centrado Slider" cols="6">
        <v-carousel
          v-model="model"
          :cycle="true"
          continuous
          :show-arrows="false"
          reverse-transition="fade-transition"
          transition="fade-transition"
          hide-delimiter-background
          class="px-0"
        >
          <v-carousel-item v-for="(text, i) in textCarrusel" :key="i">
            <v-sheet
              class="centrado sheetSlider transparent-background"
              height="100%"
              tile
            >
              <p class="subTitle-Slider">
                {{ text.text1 }}
              </p>

              <p class="title-Slider">{{ text.text2 }}</p>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>

        <div class="divBtnWhasapp">
          <v-btn color="success" class="btnWhasapp" @click="abriChat()">
            <v-icon class="mx-1">mdi-whatsapp</v-icon> CHATEA CON
            NOSOTROS</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogRegistrandoEmpresa" max-width="25%">
      <v-card class="py-5 px-5">
        <h1 class="py-5">Estamos preparando algunas cosas</h1>
        <v-progress-linear
          color="#4256BE"
          indeterminate
          rounded
          height="10"
        ></v-progress-linear>
        <h2 class="py-5">Esto no tardará mucho...</h2>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmRegistro" scrollable max-width="40%">
      <v-card>
        <div class="centrado modalConfirm">
          <p>TU ACCESO AL SISTEMA HA SIDO CREADO CON ÉXITO</p>
          <v-icon color="success" :size="100" class="iconConfirm"
            >mdi-check-circle-outline</v-icon
          >
          <h2 class="red--text">
            * Hemos enviados estos datos a tu correo, por favor guárdalos para
            futuros ingresos.
          </h2>
          <p class="details">
            El email podría demorar entre 1 y 2 minutos en llegar
          </p>
          <v-btn color="info" @click="iniciarConfiguración()"> Continuar</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CboPais from "@/components/comun/cboPais.vue";
import { mapActions } from "vuex";
export default {
  components: {
    CboPais,
  },
  data() {
    return {
      errorEmail: "",
      enviarCodigo: true,
      snackbarEmail: false,
      timeLeft: 3 * 60, // 5 minutos en segundos
      intervalId: null,
      dialogRegistrandoEmpresa: false,
      confirmRegistro: false,
      model: 0,
      id_pais: null,
      reenviarCodigo: true,
      text: "",
      colorSnakBar: "",
      icon: "",
      snackbarEmail: false,
      timeout: 2000,
      multiLine: true,
      mostrarAdvertencia: false,
      typePassword: true,
      errorMessages: [],
      textCarrusel: [
        {
          text1:
            "Conéctate donde quieras, cuando quieras, gestiona tu negocio globalmente, sin límites.1",
          text2: "¡EMPIEZA CON 30 DÍAS GRATIS!",
        },
        {
          text1:
            "Reportes financiero al instante: obten la información que necesitas con un solo clic",
          text2: "¡DESCUBRELO CON 30 DÍAS GRATIS",
        },
        {
          text1:
            "Centraliza toda tu empresa un solo sistema para todas tus operaciones y administración",
          text2: "¡PRUEBA NUESTRA PLATAFORMA HOY",
        },
        // { text1: "ddddddddddd", text2: "" },
      ],
      passwordRules: [
        (v) => !!v || "El campo es requerido",
        (v) => v.length >= 8 || "Debe tener al menos 8 caracteres",
        (v) => /[a-zA-Z]/.test(v) || "Debe contener al menos una letra",
        (v) => /[0-9]/.test(v) || "Debe contener al menos un número",
      ],
      errorPais: "",
      validandoCorreo: false,
    };
  },
  computed: {
    // Calcula los minutos restantes
    minutes() {
      return Math.floor(this.timeLeft / 60);
    },
    // Calcula los segundos restantes y formatea el valor (si es menor a 10, añade un 0 delante)
    secondsFormatted() {
      const seconds = this.timeLeft % 60;
      return seconds < 10 ? "0" + seconds : seconds;
    },
  },
  mounted() {
    // console.log(this.$vuetify.breakpoint.width);
    // Inicia el temporizador cuando el componente es montado
    // this.startTimer();
  },
  methods: {
    ...mapActions([
      // "_getPais",
      "RegistroNuevaEmpresa",
      "enviarCorreoValidacion",
      "validarCodigoVerificacion",
      "_getMenuItem",
      "validarCorreoRegistro",
    ]),
    abriChat() {
      let text = `https://api.whatsapp.com/send/?phone=${this.numero}&text=Hola, ACO. Me gustaría contactar con alguien&type=phone_number&app_absent=0`;
      window.open(text);
    },
    handlePaisSeleccionado(paisId) {
      console.log(paisId);
      this.$store.state.enterprises.data.id_pais = paisId;
      this.errorPais = "";
    },
    async validarCorreoExiste() {
      this.validandoCorreo = true;
      this.errorEmail = "";
      let res = await this.validarCorreoRegistro();
      this.validandoCorreo = false;
      if (res.estadoflag) {
        this.errorEmail = res.mensaje;
      }
    },
    startTimer() {
      this.intervalId = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.reenviarCodigo = false;
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
    async iniciarConfiguración() {
      await this._getMenuItem();
      this.$router.push({ name: "ConfiguracionInicial" }, () => {});
    },
    async enviarCorreo() {
      this.errorPais = "";
      let res = await this.validarCorreoRegistro();
      if (res.estadoflag) {
        this.errorEmail = res.mensaje;
      }
      if (
        this.$refs.frmRegistro.validate() &&
        this.validarCorreo() &&
        !res.estadoflag
      ) {
        if (
          this.$store.state.enterprises.data.email &&
          this.$store.state.enterprises.data.emailconfirmacion
        ) {
          if (!this.$store.state.enterprises.data.id_pais) {
            this.errorPais = "Dato Requerido";
            return false;
          }
          this.mostrarAdvertencia = true;
          this.timeLeft = 3 * 60;
          this.startTimer();
          let val = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
            this.$store.state.enterprises.data.email
          );
          if (val) {
            this.loading = true;
            this.loadingRegistro = true;
            this.enviarCorreoValidacion().catch((err) => {
              console.log(err);
            });
            this.reenviarCodigo = false;
            this.enviarCodigo = false;
            this.startTimer();
          } else {
            this.snackbarEmail = !val;
            this.colorSnakBar = "orange darken-2";
            this.text = "Ingrese un correo valido.";
            this.icon = "alert";
          }
        }
      } else {
        // this.errorEmail = !this.errorEmail ?"Los correos no coinciden.";
        if (!this.$store.state.enterprises.data.id_pais) {
          this.errorPais = "Dato Requerido";
          return false;
        }
      }
    },
    validarCorreo() {
      this.errorEmail = "";
      if (
        this.$store.state.enterprises.data.email &&
        this.$store.state.enterprises.data.emailconfirmacion
      ) {
        if (
          this.$store.state.enterprises.data.email !=
          this.$store.state.enterprises.data.emailconfirmacion
        ) {
          this.errorEmail = "Los Correos no coinciden";
          return false;
        }
      }
      return true;
    },
    async crearNuevaEmpresa() {
      this.errorPais = "";
      this.validatedCodigo = "";
      if (res.estadoflag) {
        this.errorEmail = res.mensaje;
      }
      let res = await this.validarCorreoRegistro();
      if (
        this.$refs.frmRegistro.validate() &&
        this.$store.state.enterprises.data.id_pais &&
        this.validarCorreo() &&
        !res.estadoflag
      ) {
        let res = await this.validarCodigoVerificacion(true);
        if (res.estadoflag) {
          this.dialogRegistrandoEmpresa = true;

          let res = await this.RegistroNuevaEmpresa();

          this.dialogRegistrandoEmpresa = false;

          this.confirmRegistro = true;
        } else {
          this.validatedCodigo = res.mensaje;
          this.snackbarEmail = true;
          this.colorSnakBar = "orange darken-2";
          this.text = res.mensaje;
          this.icon = "alert";
        }
      } else {
        if (!this.$store.state.enterprises.data.id_pais) {
          this.errorPais = "Dato Requerido";
        }
      }
    },
  },
  beforeDestroy() {
    // Asegúrate de limpiar el intervalo si el componente es destruido
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
.fill-height {
  height: 100vh;
}

.centrado {
  align-content: center;
  text-align: center;
}
.frmRegistro {
  /* border: 1px solid red; */
  padding: 5% 15%;
  box-shadow: inset;
  /* padding: 10% 0 !important; */
}
.Slider {
  color: #fff;
  background-image: url("../../../public/img/login/Slider.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  min-width: 45%;
  padding: 0 5% !important;
  /* border: 1px solid red; */
  /* padding: 0 auto; */
}
.subTitle-Slider {
  font-size: 2rem !important;
}
.title-Slider {
  font-size: 3rem !important;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.modalConfirm {
  padding: 20px 20px;
  font-weight: bold;
  font-size: 1.2rem;
}
.details {
  font-size: 1rem;
}
.sheetSlider {
  /* padding: 0 auto; */
  color: #fff;
  /* max-width: 700px; */
}

.transparent-background {
  background-color: transparent !important;
}
.divBtnWhasapp {
  /* margin: 10% 0; */
  align-content: end !important;
  text-align: end !important;
}
.btnWhasapp {
  margin: 100px 0 0 0;
  min-width: 300px !important;
  border: 2px solid #fff !important;
}
.text--black {
  color: #000 !important;
}
.text--red {
  color: red !important;
  font-weight: bold;
}
@media (max-width: 1000px) {
  img {
    width: 350px;
  }
}
@media (min-width: 1600px) {
  img {
    width: 600px !important;
    padding: 0 auto 0 0 !important;
  }
  .frmRegistro {
    /* border: 1px solid red; */
    /* padding: 0 5%; */
    box-shadow: inset;
    padding: 10% 10% !important;
  }
  .Slider {
    padding: 0 10% !important;
    /* border: 1px solid red; */
    /* padding: 0 auto; */
  }
  .btnWhasapp {
    padding: 20px 0 !important;
    margin: 100px 0 0 100px;
    min-width: 400px !important;
  }
}
</style>
